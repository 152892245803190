import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Layout from '../../components/layout'
import { Grid, Link, Typography } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import PaginationItem from '@material-ui/lab/PaginationItem'
import styles from './index.module.css'
import News from '../../components/news'
import Img from 'gatsby-image'

class CategoriaTemplate extends React.Component {
  render() {
    const postsFixed = get(
      this.props,
      'data.allContentfulArticoloFixed.edges'
    ).map((post) => post.node)
    const categoria = get(this.props, 'pageContext.label')
    const pageTitle = `Movimento Rivoluzione Fiscale - ${categoria}`

    const slug = get(this.props, 'pageContext.slug')
    const total = get(this.props, 'pageContext.total')
    const maxPages = (total - 4 > 0 ? (total - 4) % 5 : 0) + 1
    const page = get(this.props, 'pageContext.page')
    const handleChange = (event, value) => {
      window.location.href = `/${slug}/${value}/`
    }
    const postsPagination = get(
      this.props,
      'data.allContentfulArticoloPagination.edges'
    ).map((post) => post.node)

    return (
      <Layout location={this.props.location}>
        <Helmet title={pageTitle} />
        <div className={styles.container}>
          <div className={styles.gridContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={styles.titleContainer}>
                <Typography variant={'h3'}>{categoria}</Typography>
              </Grid>
              <News value={0} index={0} news={postsFixed.slice(0, 4)} />
              {postsPagination.map((post) => (
                <Grid
                  item
                  container
                  lg={12}
                  spacing={2}
                  key={`article-list-${post.id}`}
                >
                  <Grid item lg={3}>
                    {post.heroImage ? (
                      <Link href={`/articoli/${post.slug}/`}>
                        <Img alt="" fluid={post.heroImage.fluid} />
                      </Link>
                    ) : post.embed ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: post.embed.embed }}
                      />
                    ) : null}
                  </Grid>
                  <Grid item lg={4}>
                    <Link
                      href={
                        post.autore
                          ? `/articoli/${post.slug}/`
                          : post.embed
                          ? `/video/${post.slug}/`
                          : post.etichetta && post.url
                          ? post.url
                          : null
                      }
                    >
                      <Typography
                        variant={'h3'}
                        className={styles.titleArticle}
                      >
                        {post.titolo}
                      </Typography>
                    </Link>
                    {post.autore && (
                      <Typography
                        variant={'subtitle1'}
                        className={styles.author}
                      >
                        {`${post.autore.nome} ${post.autore.cognome}, ${post.dataPubblicazione}`}
                      </Typography>
                    )}
                    {post.contenuto && post.contenuto.childMarkdownRemark && (
                      <div
                        className={styles.content}
                        dangerouslySetInnerHTML={{
                          __html: post.contenuto.childMarkdownRemark.html,
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Pagination
              classes={{
                root: styles.paginationRoot,
              }}
              renderItem={(item) => (
                <PaginationItem
                  classes={{
                    selected: styles.paginationItemSelected,
                  }}
                  {...item}
                />
              )}
              count={maxPages}
              page={page}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default CategoriaTemplate

export const pageQuery = graphql`
  query ArticoloBySlug5($slug: String!, $skip: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulArticoloFixed: allContentfulArticolo(
      filter: { categorie: { elemMatch: { slug: { eq: $slug } } } }
      limit: 4
    ) {
      edges {
        node {
          id
          slug
          titolo
          contenuto {
            childMarkdownRemark {
              html
            }
          }
          autore {
            cognome
            nome
            descrizione
          }
          dataPubblicazione(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(maxWidth: 1180, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    allContentfulArticoloPagination: allContentfulArticolo(
      filter: { categorie: { elemMatch: { slug: { eq: $slug } } } }
      skip: $skip
      limit: 5
    ) {
      edges {
        node {
          id
          slug
          titolo
          contenuto {
            childMarkdownRemark {
              html
            }
          }
          autore {
            cognome
            nome
            descrizione
          }
          dataPubblicazione(formatString: "MMMM Do, YYYY")
          heroImage {
            fluid(maxWidth: 1180, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
